import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from './routes';
import * as pages from '../components/pages';
import { useAppSelector } from '../hooks/global';
import InternalAppWrapper from '../components/common/InternalAppWrapper/InternalAppWrapper';
import ScrollToTop from '../components/common/ScrollToTop/ScrollToTop';
import BackdropSpinner from '../components/controls/BackdropSpinner/BackdropSpinner';
import QToolCreateCustomExam from '../components/pages/QToolCreateCustomExam/QToolCreateCustomExam';

const renderLoader = () => {
  return <BackdropSpinner />;
};

const Router = () => {
  const accessToken = useAppSelector(state => state.user.access_token);

  if (accessToken) {
    return (
      <InternalAppWrapper>
        <Suspense fallback={renderLoader()}>
          <Routes>
            <Route path={ROUTES.SIGN_UP_THX} element={<pages.SignUpThx />} />
            <Route
              path={ROUTES.MY_PROFILE}
              element={
                <ScrollToTop>
                  <pages.MyProfile />
                </ScrollToTop>
              }
            />
            <Route path={ROUTES.COURSE_LIST} element={<pages.CourseList />} />
            <Route path={ROUTES.ACTIVE_COURSES} element={<pages.CourseList />} />
            <Route path={ROUTES.COMPLETED_COURSES} element={<pages.CourseList />} />
            <Route path={ROUTES.EXPIRED_COURSES} element={<pages.CourseList />} />

            <Route path={ROUTES.COURSES_SCORE} element={<pages.CourseScoreList />} />
            <Route path={ROUTES.OTG_RESOURCES} element={<pages.ExtraCredits />} />
            <Route path={ROUTES.QTOOL_PRINT} element={<pages.QToolPrint />} />
            <Route
              path={ROUTES.QUESTION_ANSWER_RATE}
              element={<pages.QuestionAnswerRate />}
            />
            <Route
              path={ROUTES.QTOOL_CREATE_EXAM}
              element={<pages.QToolCreateCustomExam />}
            />
            <Route path={ROUTES.QTOOL_ASSIGNMENT_EXAM} element={<pages.QToolExam />} />
            <Route
              path={ROUTES.QTOOL_ASSIGNMENT_SIM_EXAM}
              element={<pages.QToolSimExam />}
            />
            <Route
              path={ROUTES.COURSE_SCORE_BY_CATEGORIES}
              element={<pages.CourseScoreByCategories />}
            />

            <Route path={ROUTES.ADD_COURSE_LIST} element={<pages.AddCourseList />} />
            <Route
              path={ROUTES.ADD_COURSE_PAYMENT}
              element={<pages.AddCoursePayment />}
            />
            <Route
              path={ROUTES.COURSE_QUIZ_RESULT_PROGRESS_ID}
              element={<pages.CourseQuizResult />}
            />
            <Route path={ROUTES.QTOOL_EXAM_REPORT} element={<pages.QToolExamReport />} />
            <Route path={ROUTES.ADD_COURSE_THX} element={<pages.AddCourseThx />} />
            <Route path={ROUTES.ADD_COURSE_ERROR} element={<pages.ErrorPage />} />
            <Route
              path={ROUTES.COURSE_DETAILS_ASSIGNMENT}
              element={<pages.CourseAssignment />}
            />
            <Route path={ROUTES.SIMULATION_EXAM} element={<pages.SimulationExam />} />
            <Route path={ROUTES.COURSE_DETAILS} element={<pages.CourseAssignment />} />
            <Route
              path={ROUTES.SELECT_FREE_PRACTICE_EXAM}
              element={<pages.SelectFreePracticeExam />}
            />
            <Route
              path={ROUTES.FREE_PRACTICE_EXAM}
              element={<pages.FreePracticeExam />}
            />
            <Route
              path={ROUTES.FREE_PRACTICE_EXAM_RESULT}
              element={<pages.FreePracticeExamResult />}
            />
            <Route path="*" element={<Navigate to={ROUTES.COURSE_LIST} replace />} />
          </Routes>
        </Suspense>
      </InternalAppWrapper>
    );
  }
  return (
    <ScrollToTop>
      <Suspense fallback={renderLoader()}>
        <Routes>
          <Route path={ROUTES.SIGN_IN} element={<pages.SignIn />} />
          <Route path={ROUTES.FORGOT_PASSWORD} element={<pages.ForgotPassword />} />
          <Route
            path={ROUTES.SET_FORGOT_PASSWORD}
            element={<pages.SetForgotPassword />}
          />
          <Route path={ROUTES.SIGN_UP_STEP_1} element={<pages.SignUpStep1 />} />
          <Route path={ROUTES.SIGN_UP_STEP_2} element={<pages.SignUpStep2 />} />
          <Route path={ROUTES.SIGN_UP_STEP_3} element={<pages.SignUpStep3 />} />
          <Route
            path={ROUTES.SELECT_FREE_PRACTICE_EXAM}
            element={<pages.SelectFreePracticeExam />}
          />
          <Route path={ROUTES.FREE_PRACTICE_EXAM} element={<pages.FreePracticeExam />} />
          <Route
            path={ROUTES.FREE_PRACTICE_EXAM_RESULT}
            element={<pages.FreePracticeExamResult />}
          />
          <Route
            path={ROUTES.OLD_FREE_EXAMS}
            element={<Navigate to={ROUTES.SELECT_FREE_PRACTICE_EXAM} replace />}
          />
          <Route
            path={ROUTES.OLD_FORGOT_PASSWORD}
            element={<Navigate to={ROUTES.FORGOT_PASSWORD} replace />}
          />
          <Route
            path={ROUTES.OLD_SIGN_UP_STEP_3}
            element={<Navigate to={ROUTES.SIGN_UP_STEP_1} replace />}
          />
          <Route
            path={ROUTES.OLD_SIGN_UP_STEP_2}
            element={<Navigate to={ROUTES.SIGN_UP_STEP_1} replace />}
          />
          <Route
            path={ROUTES.OLD_SIGN_UP_STEP_1}
            element={<Navigate to={ROUTES.SIGN_UP_STEP_1} replace />}
          />
          <Route path="*" element={<Navigate to={ROUTES.SIGN_IN} replace />} />
        </Routes>
      </Suspense>
    </ScrollToTop>
  );
};

export default Router;
